import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import PortableText from "../components/Blog/portableText";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import PurchaseRefinance from "../components/Repeating/PurchaseRefinance";
import Testimonials from "../components/Repeating/Testimonials";
import LoanPrograms from "../components/Repeating/LoanPrograms";
import Resources from "../components/Repeating/Resources";
import About from "../components/Repeating/About";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonIcon from "../components/Button/ButtonIcon";

export const query = graphql`
  query StateTemplateQuery($id: String!) {
    state: sanityStates(id: { eq: $id }) {
      id
      title
      seoTitle
      metaDescription
      openGraphImage {
        asset {
          url
        }
      }
      twitterCardImage {
        asset {
          url
        }
      }
      heroDesktop {
        asset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      heroMobile {
        asset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      whyWeLoveImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 1200)
        }
      }
      _rawWhyWeLoveText
    }
  }
`;

const Template = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title={data.state.seoTitle}
        description={data.state.metaDescription}
        openGraphImage={
          data.state.openGraphImage && data.state.openGraphImage.asset.url
        }
        twitterOpenGraphImage={
          data.state.twitterCardImage && data.state.twitterCardImage.asset.url
        }
      />

      <section className="relative mb-14 overflow-hidden pt-52 pb-22 md:mb-22 md:py-56">
        <div className="absolute left-0 top-0 hidden h-full w-full md:block">
          <GatsbyImage
            image={data.state.heroDesktop.asset.gatsbyImageData}
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="absolute left-0 top-0 h-full w-full md:hidden">
          <GatsbyImage
            image={data.state.heroMobile.asset.gatsbyImageData}
            loading="eager"
            className="h-full"
            imgClassName="object-top"
          />
        </div>
        <div className="container">
          <div className="relative mx-auto max-w-[520px] text-center md:mx-0 md:text-left">
            <div className="mb-4 hidden font-heading text-mobile-7xl font-extrabold text-white md:block md:text-7xl">
              Destination: home
            </div>
            <div className="mb-2.5 space-x-2.5 font-heading font-semibold uppercase tracking-wider text-primary-50/70 md:hidden">
              <i class="fa-solid fa-location-dot"></i>
              <span>Destination:</span>
            </div>
            <div className="mb-8 font-heading text-mobile-7xl font-bold text-white md:hidden">
              Home
            </div>
            <p className="mb-10 text-xl text-white md:mb-3 md:text-[1.375rem] md:leading-[2rem]">
              A personal home loan experience from dedicated mortgage experts.
              Trusted since 1989.
            </p>
            <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
              <ButtonSolid
                href="/home-purchase/"
                text="Purchase"
                altStyle={2}
                className="w-full md:w-auto"
              />
              <ButtonSolid
                href="/home-refinance/"
                text="Refinance"
                className="w-full md:w-auto"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="relative mb-14 overflow-hidden pt-52 pb-22 md:mb-22 md:py-56">
        <div className="absolute left-0 top-0 hidden h-full w-full md:block">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero-homepage.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="absolute left-0 top-0 h-full w-full md:hidden">
          <StaticImage
            src="../images/1.0 Homepage/Hero mobile.jpg"
            loading="eager"
            className="h-full"
            imgClassName="object-top"
          />
        </div>
        <div className="container">
          <div className="relative mx-auto max-w-[520px] text-center md:mx-0 md:text-left">
            <div className="mb-4 hidden font-heading text-mobile-7xl font-extrabold text-white md:block md:text-7xl">
              Destination: home
            </div>
            <div className="mb-2.5 space-x-2.5 font-heading font-semibold uppercase tracking-wider text-primary-50/70 md:hidden">
              <i class="fa-solid fa-location-dot"></i>
              <span>Destination:</span>
            </div>
            <div className="mb-8 font-heading text-mobile-7xl font-bold text-white md:hidden">
              Home
            </div>
            <p className="mb-10 text-xl text-white md:mb-3 md:text-[1.375rem] md:leading-[2rem]">
              A personal home loan experience from dedicated mortgage experts.
              Trusted since 1989.
            </p>
            <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
              <ButtonSolid
                href="/home-purchase/"
                text="Purchase"
                altStyle={2}
                className="w-full md:w-auto"
              />
              <ButtonSolid
                href="/home-refinance/"
                text="Refinance"
                className="w-full md:w-auto"
              />
            </div>
          </div>
        </div>
      </section> */}

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <StaticImage
                src="../images/1.0 Homepage/2.0 Intro.png"
                loading="lazy"
                width={560}
              />
            </div>
            <div className="order-1 md:order-2">
              <h1>
                {data.state.title.charAt(0) === "A" ||
                data.state.title.charAt(0) === "O"
                  ? "An"
                  : "A"}{" "}
                {data.state.title} Mortgage Lender With Individual Focus
              </h1>
              <p>
                We’re here especially for you—whether you're purchasing a home,
                refinancing your existing loan, or want some cash for home
                improvements. You get all the information you need from our
                knowledgeable {data.state.title} mortgage advisors, an easy
                5-step loan process from beginning to close, and personalized
                service.
              </p>
              <ButtonIcon href="/about/" text="Learn More" altStyle={2} />
            </div>
          </div>
        </div>
      </section>

      <ValueProps />
      <PurchaseRefinance location={data.state.title} />
      <Testimonials />
      <LoanPrograms />
      <Resources />
      <About />

      {data.state._rawWhyWeLoveText && data.state.whyWeLoveImage && (
        <section className="mb-20 md:mb-32">
          <div className="container">
            <GatsbyImage
              image={data.state.whyWeLoveImage.asset.gatsbyImageData}
              className="z-0 mb-10 rounded-3xl"
            />
            <div className="grid md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
              <h2>Why We Love {data.state.title}</h2>
              <PortableText blocks={data.state._rawWhyWeLoveText} />
            </div>
          </div>
        </section>
      )}

      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export default Template;
